import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b385702 = () => interopDefault(import('../pages/authorization.vue' /* webpackChunkName: "pages/authorization" */))
const _5c093add = () => interopDefault(import('../pages/event-tracking-report.vue' /* webpackChunkName: "pages/event-tracking-report" */))
const _6346d9c1 = () => interopDefault(import('../pages/health-check.vue' /* webpackChunkName: "pages/health-check" */))
const _7260f649 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _c62e76ae = () => interopDefault(import('../pages/market/index.vue' /* webpackChunkName: "pages/market/index" */))
const _0e9cdd87 = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _63f8e2ca = () => interopDefault(import('../pages/member/index/index.vue' /* webpackChunkName: "pages/member/index/index" */))
const _2b70ac62 = () => interopDefault(import('../pages/member/index/favorites.vue' /* webpackChunkName: "pages/member/index/favorites" */))
const _60a9af43 = () => interopDefault(import('../pages/member/index/my-profile/index.vue' /* webpackChunkName: "pages/member/index/my-profile/index" */))
const _670f10f4 = () => interopDefault(import('../pages/member/index/my-profile/index/index.vue' /* webpackChunkName: "pages/member/index/my-profile/index/index" */))
const _2b7d5875 = () => interopDefault(import('../pages/member/index/my-profile/index/check-registration-status.vue' /* webpackChunkName: "pages/member/index/my-profile/index/check-registration-status" */))
const _5734a4d6 = () => interopDefault(import('../pages/member/index/my-profile/index/events.vue' /* webpackChunkName: "pages/member/index/my-profile/index/events" */))
const _f73493b6 = () => interopDefault(import('../pages/member/index/my-profile/index/examination.vue' /* webpackChunkName: "pages/member/index/my-profile/index/examination" */))
const _e8946996 = () => interopDefault(import('../pages/member/index/my-profile/index/main.vue' /* webpackChunkName: "pages/member/index/my-profile/index/main" */))
const _594430e6 = () => interopDefault(import('../pages/member/index/my-profile/index/past-training-examination.vue' /* webpackChunkName: "pages/member/index/my-profile/index/past-training-examination" */))
const _2f3cfac5 = () => interopDefault(import('../pages/member/index/my-profile/index/set-member-privilege.vue' /* webpackChunkName: "pages/member/index/my-profile/index/set-member-privilege" */))
const _b1eff854 = () => interopDefault(import('../pages/member/index/my-profile/index/training.vue' /* webpackChunkName: "pages/member/index/my-profile/index/training" */))
const _d1443834 = () => interopDefault(import('../pages/member/index/my-profile/_vue.vue' /* webpackChunkName: "pages/member/index/my-profile/_vue" */))
const _039c8c74 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _3aff41fc = () => interopDefault(import('../pages/rules-regulations/index.vue' /* webpackChunkName: "pages/rules-regulations/index" */))
const _5b395b7f = () => interopDefault(import('../pages/rules-regulations/index/index.vue' /* webpackChunkName: "pages/rules-regulations/index/index" */))
const _a1e7a63e = () => interopDefault(import('../pages/rules-regulations/index/circulars.vue' /* webpackChunkName: "pages/rules-regulations/index/circulars" */))
const _0f766f48 = () => interopDefault(import('../pages/rules-regulations/index/forms.vue' /* webpackChunkName: "pages/rules-regulations/index/forms" */))
const _5c3f6576 = () => interopDefault(import('../pages/rules-regulations/index/regulations.vue' /* webpackChunkName: "pages/rules-regulations/index/regulations" */))
const _0865e58f = () => interopDefault(import('../pages/rules-regulations/index/search-result.vue' /* webpackChunkName: "pages/rules-regulations/index/search-result" */))
const _dd046422 = () => interopDefault(import('../pages/rules-regulations/index/update/index.vue' /* webpackChunkName: "pages/rules-regulations/index/update/index" */))
const _6ff1e751 = () => interopDefault(import('../pages/rules-regulations/index/update/circulars.vue' /* webpackChunkName: "pages/rules-regulations/index/update/circulars" */))
const _2e893acc = () => interopDefault(import('../pages/rules-regulations/index/update/forms.vue' /* webpackChunkName: "pages/rules-regulations/index/update/forms" */))
const _7aa043e6 = () => interopDefault(import('../pages/rules-regulations/index/update/regulations.vue' /* webpackChunkName: "pages/rules-regulations/index/update/regulations" */))
const _1e609130 = () => interopDefault(import('../pages/search-result.vue' /* webpackChunkName: "pages/search-result" */))
const _7e807400 = () => interopDefault(import('../pages/about/event-calendar/index.vue' /* webpackChunkName: "pages/about/event-calendar/index" */))
const _a7f31f78 = () => interopDefault(import('../pages/about/setsource/index.vue' /* webpackChunkName: "pages/about/setsource/index" */))
const _69afeb9a = () => interopDefault(import('../pages/education-research/research/index.vue' /* webpackChunkName: "pages/education-research/research/index" */))
const _4bf81892 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _0eb1d0f4 = () => interopDefault(import('../pages/error/500.vue' /* webpackChunkName: "pages/error/500" */))
const _0edc1777 = () => interopDefault(import('../pages/error/503.vue' /* webpackChunkName: "pages/error/503" */))
const _3d809dbc = () => interopDefault(import('../pages/error/oldversion.vue' /* webpackChunkName: "pages/error/oldversion" */))
const _7277f3e6 = () => interopDefault(import('../pages/listing/ipo/index.vue' /* webpackChunkName: "pages/listing/ipo/index" */))
const _78827d9f = () => interopDefault(import('../pages/market/get-quote/index.vue' /* webpackChunkName: "pages/market/get-quote/index" */))
const _0be246c6 = () => interopDefault(import('../pages/market/indexs/index.vue' /* webpackChunkName: "pages/market/indexs/index" */))
const _67ecb930 = () => interopDefault(import('../pages/market/news-and-alert/index.vue' /* webpackChunkName: "pages/market/news-and-alert/index" */))
const _0a57fbeb = () => interopDefault(import('../pages/market/news-and-alert/index/index.vue' /* webpackChunkName: "pages/market/news-and-alert/index/index" */))
const _5af15c61 = () => interopDefault(import('../pages/market/news-and-alert/index/auditors-opinion.vue' /* webpackChunkName: "pages/market/news-and-alert/index/auditors-opinion" */))
const _50e8a5d1 = () => interopDefault(import('../pages/market/news-and-alert/index/cashbalance-c-summary.vue' /* webpackChunkName: "pages/market/news-and-alert/index/cashbalance-c-summary" */))
const _103311be = () => interopDefault(import('../pages/market/news-and-alert/index/dynamic-price-band.vue' /* webpackChunkName: "pages/market/news-and-alert/index/dynamic-price-band" */))
const _3af01461 = () => interopDefault(import('../pages/market/news-and-alert/index/market-alerts.vue' /* webpackChunkName: "pages/market/news-and-alert/index/market-alerts" */))
const _54f60e4a = () => interopDefault(import('../pages/market/news-and-alert/index/news.vue' /* webpackChunkName: "pages/market/news-and-alert/index/news" */))
const _0490dc29 = () => interopDefault(import('../pages/market/news-and-alert/index/sign-posting.vue' /* webpackChunkName: "pages/market/news-and-alert/index/sign-posting" */))
const _11b0758c = () => interopDefault(import('../pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading.vue' /* webpackChunkName: "pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading" */))
const _37cf3ffa = () => interopDefault(import('../pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/c-sign.vue' /* webpackChunkName: "pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/c-sign" */))
const _8281a23a = () => interopDefault(import('../pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/market-surveillance-measure-list.vue' /* webpackChunkName: "pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/market-surveillance-measure-list" */))
const _8f37faba = () => interopDefault(import('../pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/summary.vue' /* webpackChunkName: "pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/summary" */))
const _57b0cee2 = () => interopDefault(import('../pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/temporary-trading.vue' /* webpackChunkName: "pages/market/news-and-alert/index/surveillance-c-sign-temporary-trading/temporary-trading" */))
const _cf78ab02 = () => interopDefault(import('../pages/market/news-and-alert/index/trading-halt-suspension-pause.vue' /* webpackChunkName: "pages/market/news-and-alert/index/trading-halt-suspension-pause" */))
const _34fb075c = () => interopDefault(import('../pages/market/statistics.vue' /* webpackChunkName: "pages/market/statistics" */))
const _a238ce42 = () => interopDefault(import('../pages/market/statistics/index.vue' /* webpackChunkName: "pages/market/statistics/index" */))
const _c01e6fc2 = () => interopDefault(import('../pages/market/statistics/five-days.vue' /* webpackChunkName: "pages/market/statistics/five-days" */))
const _22636fdc = () => interopDefault(import('../pages/market/statistics/investor-type.vue' /* webpackChunkName: "pages/market/statistics/investor-type" */))
const _64ce724e = () => interopDefault(import('../pages/market/statistics/nvdr.vue' /* webpackChunkName: "pages/market/statistics/nvdr" */))
const _3861069c = () => interopDefault(import('../pages/market/statistics/nvdr/index.vue' /* webpackChunkName: "pages/market/statistics/nvdr/index" */))
const _1b94015f = () => interopDefault(import('../pages/market/statistics/nvdr/main.vue' /* webpackChunkName: "pages/market/statistics/nvdr/main" */))
const _9373e894 = () => interopDefault(import('../pages/market/statistics/nvdr/outstanding-share.vue' /* webpackChunkName: "pages/market/statistics/nvdr/outstanding-share" */))
const _65ade096 = () => interopDefault(import('../pages/market/statistics/nvdr/trading-by-method.vue' /* webpackChunkName: "pages/market/statistics/nvdr/trading-by-method" */))
const _1c7dcbba = () => interopDefault(import('../pages/market/statistics/nvdr/trading-by-sector.vue' /* webpackChunkName: "pages/market/statistics/nvdr/trading-by-sector" */))
const _4b44c272 = () => interopDefault(import('../pages/market/statistics/nvdr/trading-by-stock.vue' /* webpackChunkName: "pages/market/statistics/nvdr/trading-by-stock" */))
const _09cdc749 = () => interopDefault(import('../pages/market/statistics/program-trading-value.vue' /* webpackChunkName: "pages/market/statistics/program-trading-value" */))
const _27dccccb = () => interopDefault(import('../pages/market/statistics/short-sales/index.vue' /* webpackChunkName: "pages/market/statistics/short-sales/index" */))
const _50714f0e = () => interopDefault(import('../pages/market/statistics/short-sales/index/index.vue' /* webpackChunkName: "pages/market/statistics/short-sales/index/index" */))
const _474a5b62 = () => interopDefault(import('../pages/market/statistics/short-sales/index/outstanding-short-positions.vue' /* webpackChunkName: "pages/market/statistics/short-sales/index/outstanding-short-positions" */))
const _53e888a4 = () => interopDefault(import('../pages/market/statistics/short-sales/index/total-short-sales.vue' /* webpackChunkName: "pages/market/statistics/short-sales/index/total-short-sales" */))
const _4a841dc6 = () => interopDefault(import('../pages/market/statistics/short-sell.vue' /* webpackChunkName: "pages/market/statistics/short-sell" */))
const _97a572ce = () => interopDefault(import('../pages/market/statistics/market-statistics/capital-raise.vue' /* webpackChunkName: "pages/market/statistics/market-statistics/capital-raise" */))
const _395f577a = () => interopDefault(import('../pages/market/statistics/market-statistics/ipo.vue' /* webpackChunkName: "pages/market/statistics/market-statistics/ipo" */))
const _15cef366 = () => interopDefault(import('../pages/market/statistics/market-statistics/monthly-report/_slug.vue' /* webpackChunkName: "pages/market/statistics/market-statistics/monthly-report/_slug" */))
const _148668d1 = () => interopDefault(import('../pages/market/stock-calendar/index.vue' /* webpackChunkName: "pages/market/stock-calendar/index" */))
const _241d9228 = () => interopDefault(import('../pages/rules-regulations/details.vue' /* webpackChunkName: "pages/rules-regulations/details" */))
const _2e61f386 = () => interopDefault(import('../pages/rules-regulations/exchange-enquiries.vue' /* webpackChunkName: "pages/rules-regulations/exchange-enquiries" */))
const _6bcb42a5 = () => interopDefault(import('../pages/rules-regulations/regulatory-statistics/index.vue' /* webpackChunkName: "pages/rules-regulations/regulatory-statistics/index" */))
const _5c49ec75 = () => interopDefault(import('../pages/rules-regulations/rules-canceled.vue' /* webpackChunkName: "pages/rules-regulations/rules-canceled" */))
const _3e575a59 = () => interopDefault(import('../pages/tch/overview.vue' /* webpackChunkName: "pages/tch/overview" */))
const _520cb474 = () => interopDefault(import('../pages/tch/rules-regulations/index.vue' /* webpackChunkName: "pages/tch/rules-regulations/index" */))
const _5a483fc9 = () => interopDefault(import('../pages/tch/rules-regulations/index/index.vue' /* webpackChunkName: "pages/tch/rules-regulations/index/index" */))
const _081bdc2b = () => interopDefault(import('../pages/tch/rules-regulations/index/circulars.vue' /* webpackChunkName: "pages/tch/rules-regulations/index/circulars" */))
const _1158a6b4 = () => interopDefault(import('../pages/tch/rules-regulations/index/forms.vue' /* webpackChunkName: "pages/tch/rules-regulations/index/forms" */))
const _9fbf2b80 = () => interopDefault(import('../pages/tch/rules-regulations/index/regulations.vue' /* webpackChunkName: "pages/tch/rules-regulations/index/regulations" */))
const _5496d7ed = () => interopDefault(import('../pages/tsd/overview.vue' /* webpackChunkName: "pages/tsd/overview" */))
const _3c84cdb2 = () => interopDefault(import('../pages/tsd/rules-regulations/index.vue' /* webpackChunkName: "pages/tsd/rules-regulations/index" */))
const _02d8c6b5 = () => interopDefault(import('../pages/tsd/rules-regulations/index/index.vue' /* webpackChunkName: "pages/tsd/rules-regulations/index/index" */))
const _55f70d17 = () => interopDefault(import('../pages/tsd/rules-regulations/index/circulars.vue' /* webpackChunkName: "pages/tsd/rules-regulations/index/circulars" */))
const _c03798dc = () => interopDefault(import('../pages/tsd/rules-regulations/index/forms.vue' /* webpackChunkName: "pages/tsd/rules-regulations/index/forms" */))
const _1819dfa8 = () => interopDefault(import('../pages/tsd/rules-regulations/index/regulations.vue' /* webpackChunkName: "pages/tsd/rules-regulations/index/regulations" */))
const _e654ab6a = () => interopDefault(import('../pages/about/event-calendar/event/index.vue' /* webpackChunkName: "pages/about/event-calendar/event/index" */))
const _935599f4 = () => interopDefault(import('../pages/about/event-calendar/holiday.vue' /* webpackChunkName: "pages/about/event-calendar/holiday" */))
const _489e8113 = () => interopDefault(import('../pages/about/join-us/main.vue' /* webpackChunkName: "pages/about/join-us/main" */))
const _37caf09c = () => interopDefault(import('../pages/about/setsource/insights/index.vue' /* webpackChunkName: "pages/about/setsource/insights/index" */))
const _235ad24e = () => interopDefault(import('../pages/about/setsource/news-release/index.vue' /* webpackChunkName: "pages/about/setsource/news-release/index" */))
const _a4ef3558 = () => interopDefault(import('../pages/about/setsource/search-result.vue' /* webpackChunkName: "pages/about/setsource/search-result" */))
const _5d960ea3 = () => interopDefault(import('../pages/about/setsource/setsource-vdo.vue' /* webpackChunkName: "pages/about/setsource/setsource-vdo" */))
const _8c6f9a82 = () => interopDefault(import('../pages/about/setsource/yaek-yae/index.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/index" */))
const _5a9b0b3c = () => interopDefault(import('../pages/education-research/education/setbook/index.vue' /* webpackChunkName: "pages/education-research/education/setbook/index" */))
const _57d3b065 = () => interopDefault(import('../pages/education-research/education/setbook/index/pocket-book/main.vue' /* webpackChunkName: "pages/education-research/education/setbook/index/pocket-book/main" */))
const _0c60de08 = () => interopDefault(import('../pages/education-research/education/setbook/index/text-book/main.vue' /* webpackChunkName: "pages/education-research/education/setbook/index/text-book/main" */))
const _ec9aa24e = () => interopDefault(import('../pages/education-research/research/article-paper/index.vue' /* webpackChunkName: "pages/education-research/research/article-paper/index" */))
const _9dc15e6e = () => interopDefault(import('../pages/education-research/research/market-report/index.vue' /* webpackChunkName: "pages/education-research/research/market-report/index" */))
const _1ddd5328 = () => interopDefault(import('../pages/education-research/research/overview.vue' /* webpackChunkName: "pages/education-research/research/overview" */))
const _6de4a043 = () => interopDefault(import('../pages/education-research/research/search-result.vue' /* webpackChunkName: "pages/education-research/research/search-result" */))
const _4189a72a = () => interopDefault(import('../pages/listing/ipo/first-trading-day/index.vue' /* webpackChunkName: "pages/listing/ipo/first-trading-day/index" */))
const _05970807 = () => interopDefault(import('../pages/listing/ipo/ipo-statistics/index.vue' /* webpackChunkName: "pages/listing/ipo/ipo-statistics/index" */))
const _9a7c8354 = () => interopDefault(import('../pages/listing/ipo/upcoming-ipo/index.vue' /* webpackChunkName: "pages/listing/ipo/upcoming-ipo/index" */))
const _21faaf8b = () => interopDefault(import('../pages/listing/other-instruments/dw/index.vue' /* webpackChunkName: "pages/listing/other-instruments/dw/index" */))
const _6dca21ce = () => interopDefault(import('../pages/listing/other-instruments/dw/index/index.vue' /* webpackChunkName: "pages/listing/other-instruments/dw/index/index" */))
const _4610ee8f = () => interopDefault(import('../pages/listing/other-instruments/dw/index/eligible-underlying.vue' /* webpackChunkName: "pages/listing/other-instruments/dw/index/eligible-underlying" */))
const _c4135e54 = () => interopDefault(import('../pages/listing/other-instruments/dw/index/issuers.vue' /* webpackChunkName: "pages/listing/other-instruments/dw/index/issuers" */))
const _11987afb = () => interopDefault(import('../pages/listing/other-instruments/dw/index/outstanding-report.vue' /* webpackChunkName: "pages/listing/other-instruments/dw/index/outstanding-report" */))
const _7db41258 = () => interopDefault(import('../pages/market/get-quote/dr/index.vue' /* webpackChunkName: "pages/market/get-quote/dr/index" */))
const _9e69e352 = () => interopDefault(import('../pages/market/get-quote/dr/index/index.vue' /* webpackChunkName: "pages/market/get-quote/dr/index/index" */))
const _584198fa = () => interopDefault(import('../pages/market/get-quote/drx/index.vue' /* webpackChunkName: "pages/market/get-quote/drx/index" */))
const _595c0806 = () => interopDefault(import('../pages/market/get-quote/drx/index/index.vue' /* webpackChunkName: "pages/market/get-quote/drx/index/index" */))
const _acc516ce = () => interopDefault(import('../pages/market/get-quote/dw/index.vue' /* webpackChunkName: "pages/market/get-quote/dw/index" */))
const _441c645c = () => interopDefault(import('../pages/market/get-quote/dw/index/index.vue' /* webpackChunkName: "pages/market/get-quote/dw/index/index" */))
const _43d94eb2 = () => interopDefault(import('../pages/market/get-quote/etf/index.vue' /* webpackChunkName: "pages/market/get-quote/etf/index" */))
const _7e2e85ea = () => interopDefault(import('../pages/market/get-quote/etf/index/index.vue' /* webpackChunkName: "pages/market/get-quote/etf/index/index" */))
const _d6be5874 = () => interopDefault(import('../pages/market/get-quote/preferred-shares/index.vue' /* webpackChunkName: "pages/market/get-quote/preferred-shares/index" */))
const _5d54edc9 = () => interopDefault(import('../pages/market/get-quote/preferred-shares/index/index.vue' /* webpackChunkName: "pages/market/get-quote/preferred-shares/index/index" */))
const _5721da26 = () => interopDefault(import('../pages/market/get-quote/stock/index.vue' /* webpackChunkName: "pages/market/get-quote/stock/index" */))
const _9260a7ae = () => interopDefault(import('../pages/market/get-quote/stock/index/index.vue' /* webpackChunkName: "pages/market/get-quote/stock/index/index" */))
const _fbf1c608 = () => interopDefault(import('../pages/market/get-quote/warrants/index.vue' /* webpackChunkName: "pages/market/get-quote/warrants/index" */))
const _149e1302 = () => interopDefault(import('../pages/market/get-quote/warrants/index/index.vue' /* webpackChunkName: "pages/market/get-quote/warrants/index/index" */))
const _acc7ab30 = () => interopDefault(import('../pages/market/information/member-list/index.vue' /* webpackChunkName: "pages/market/information/member-list/index" */))
const _195f69cd = () => interopDefault(import('../pages/market/news-and-alert/market-clarification.vue' /* webpackChunkName: "pages/market/news-and-alert/market-clarification" */))
const _0c9181a5 = () => interopDefault(import('../pages/market/news-and-alert/newsdetails.vue' /* webpackChunkName: "pages/market/news-and-alert/newsdetails" */))
const _70430baa = () => interopDefault(import('../pages/market/product/dr/index.vue' /* webpackChunkName: "pages/market/product/dr/index" */))
const _0b3eaaad = () => interopDefault(import('../pages/market/product/dr/index/index.vue' /* webpackChunkName: "pages/market/product/dr/index/index" */))
const _cff88872 = () => interopDefault(import('../pages/market/product/dr/index/information.vue' /* webpackChunkName: "pages/market/product/dr/index/information" */))
const _26fa286e = () => interopDefault(import('../pages/market/product/dr/index/overview.vue' /* webpackChunkName: "pages/market/product/dr/index/overview" */))
const _e671c238 = () => interopDefault(import('../pages/market/product/drx/index.vue' /* webpackChunkName: "pages/market/product/drx/index" */))
const _715c2b32 = () => interopDefault(import('../pages/market/product/drx/index/index.vue' /* webpackChunkName: "pages/market/product/drx/index/index" */))
const _e523f8be = () => interopDefault(import('../pages/market/product/drx/index/marketdata.vue' /* webpackChunkName: "pages/market/product/drx/index/marketdata" */))
const _3a06604e = () => interopDefault(import('../pages/market/product/drx/index/news.vue' /* webpackChunkName: "pages/market/product/drx/index/news" */))
const _aa01d318 = () => interopDefault(import('../pages/market/product/drx/index/overview.vue' /* webpackChunkName: "pages/market/product/drx/index/overview" */))
const _58ba896f = () => interopDefault(import('../pages/market/product/dw/index.vue' /* webpackChunkName: "pages/market/product/dw/index" */))
const _c2dffe9c = () => interopDefault(import('../pages/market/product/dw/index/index.vue' /* webpackChunkName: "pages/market/product/dw/index/index" */))
const _a5b94f6e = () => interopDefault(import('../pages/market/product/dw/index/overview.vue' /* webpackChunkName: "pages/market/product/dw/index/overview" */))
const _bacb490a = () => interopDefault(import('../pages/market/product/dw/index/search/index.vue' /* webpackChunkName: "pages/market/product/dw/index/search/index" */))
const _be388b9a = () => interopDefault(import('../pages/market/product/dw/index/search/_slug.vue' /* webpackChunkName: "pages/market/product/dw/index/search/_slug" */))
const _1298de91 = () => interopDefault(import('../pages/market/product/etf/index.vue' /* webpackChunkName: "pages/market/product/etf/index" */))
const _722e7454 = () => interopDefault(import('../pages/market/product/etf/index/index.vue' /* webpackChunkName: "pages/market/product/etf/index/index" */))
const _af28b458 = () => interopDefault(import('../pages/market/product/etf/index/marketdata.vue' /* webpackChunkName: "pages/market/product/etf/index/marketdata" */))
const _0155a967 = () => interopDefault(import('../pages/market/product/etf/index/overview.vue' /* webpackChunkName: "pages/market/product/etf/index/overview" */))
const _ff44e4e0 = () => interopDefault(import('../pages/market/product/stock/index.vue' /* webpackChunkName: "pages/market/product/stock/index" */))
const _aae4afda = () => interopDefault(import('../pages/market/product/stock/index/index.vue' /* webpackChunkName: "pages/market/product/stock/index/index" */))
const _101c6948 = () => interopDefault(import('../pages/market/product/stock/index/overview.vue' /* webpackChunkName: "pages/market/product/stock/index/overview" */))
const _52e633b7 = () => interopDefault(import('../pages/market/product/stock/index/search.vue' /* webpackChunkName: "pages/market/product/stock/index/search" */))
const _1fbfd23f = () => interopDefault(import('../pages/market/product/stock/index/top-ranking.vue' /* webpackChunkName: "pages/market/product/stock/index/top-ranking" */))
const _bc63aff8 = () => interopDefault(import('../pages/market/stock-calendar/x-calendar.vue' /* webpackChunkName: "pages/market/stock-calendar/x-calendar" */))
const _e2cdd336 = () => interopDefault(import('../pages/tsd/information/company-list.vue' /* webpackChunkName: "pages/tsd/information/company-list" */))
const _495d2b8c = () => interopDefault(import('../pages/tsd/information/debenture/index.vue' /* webpackChunkName: "pages/tsd/information/debenture/index" */))
const _bf2c76f6 = () => interopDefault(import('../pages/tsd/information/e-proxy-voting.vue' /* webpackChunkName: "pages/tsd/information/e-proxy-voting" */))
const _2cd821bc = () => interopDefault(import('../pages/tsd/information/isin-cfi-fisn.vue' /* webpackChunkName: "pages/tsd/information/isin-cfi-fisn" */))
const _1b4e85ff = () => interopDefault(import('../pages/about/event-calendar/event/eventdetails.vue' /* webpackChunkName: "pages/about/event-calendar/event/eventdetails" */))
const _ac52c7e0 = () => interopDefault(import('../pages/about/event-calendar/event/overview.vue' /* webpackChunkName: "pages/about/event-calendar/event/overview" */))
const _df11c380 = () => interopDefault(import('../pages/about/setsource/insights/article.vue' /* webpackChunkName: "pages/about/setsource/insights/article" */))
const _5e68ea98 = () => interopDefault(import('../pages/about/setsource/insights/ebook.vue' /* webpackChunkName: "pages/about/setsource/insights/ebook" */))
const _3ef95ccd = () => interopDefault(import('../pages/about/setsource/insights/elearning.vue' /* webpackChunkName: "pages/about/setsource/insights/elearning" */))
const _1cd214c4 = () => interopDefault(import('../pages/about/setsource/insights/infographic.vue' /* webpackChunkName: "pages/about/setsource/insights/infographic" */))
const _1b765142 = () => interopDefault(import('../pages/about/setsource/insights/main.vue' /* webpackChunkName: "pages/about/setsource/insights/main" */))
const _91404076 = () => interopDefault(import('../pages/about/setsource/insights/video.vue' /* webpackChunkName: "pages/about/setsource/insights/video" */))
const _50d5491c = () => interopDefault(import('../pages/about/setsource/news-release/article.vue' /* webpackChunkName: "pages/about/setsource/news-release/article" */))
const _49f8cc4a = () => interopDefault(import('../pages/about/setsource/news-release/ebook.vue' /* webpackChunkName: "pages/about/setsource/news-release/ebook" */))
const _377d157f = () => interopDefault(import('../pages/about/setsource/news-release/elearning.vue' /* webpackChunkName: "pages/about/setsource/news-release/elearning" */))
const _034a68f6 = () => interopDefault(import('../pages/about/setsource/news-release/infographic.vue' /* webpackChunkName: "pages/about/setsource/news-release/infographic" */))
const _6538f76d = () => interopDefault(import('../pages/about/setsource/news-release/main.vue' /* webpackChunkName: "pages/about/setsource/news-release/main" */))
const _ba207d12 = () => interopDefault(import('../pages/about/setsource/news-release/video.vue' /* webpackChunkName: "pages/about/setsource/news-release/video" */))
const _efa738ba = () => interopDefault(import('../pages/about/setsource/yaek-yae/article.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/article" */))
const _3f33a68a = () => interopDefault(import('../pages/about/setsource/yaek-yae/ebook.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/ebook" */))
const _1e72d570 = () => interopDefault(import('../pages/about/setsource/yaek-yae/elearning.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/elearning" */))
const _03cff0a7 = () => interopDefault(import('../pages/about/setsource/yaek-yae/infographic.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/infographic" */))
const _725544dc = () => interopDefault(import('../pages/about/setsource/yaek-yae/main.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/main" */))
const _542162b1 = () => interopDefault(import('../pages/about/setsource/yaek-yae/podcast.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/podcast" */))
const _395d21e8 = () => interopDefault(import('../pages/about/setsource/yaek-yae/video.vue' /* webpackChunkName: "pages/about/setsource/yaek-yae/video" */))
const _57127904 = () => interopDefault(import('../pages/education-research/education/happymoney/glossary/index.vue' /* webpackChunkName: "pages/education-research/education/happymoney/glossary/index" */))
const _35d01e2e = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/index.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/index" */))
const _45053262 = () => interopDefault(import('../pages/education-research/education/happymoney/search-result.vue' /* webpackChunkName: "pages/education-research/education/happymoney/search-result" */))
const _477ddd82 = () => interopDefault(import('../pages/education-research/research/article-paper/main.vue' /* webpackChunkName: "pages/education-research/research/article-paper/main" */))
const _e8a0890e = () => interopDefault(import('../pages/education-research/research/article-paper/Oldslug.vue' /* webpackChunkName: "pages/education-research/research/article-paper/Oldslug" */))
const _168927e2 = () => interopDefault(import('../pages/education-research/research/article-paper/set-cmri-research-paper.vue' /* webpackChunkName: "pages/education-research/research/article-paper/set-cmri-research-paper" */))
const _1d810918 = () => interopDefault(import('../pages/education-research/research/article-paper/set-cmri-working-paper.vue' /* webpackChunkName: "pages/education-research/research/article-paper/set-cmri-working-paper" */))
const _525517c6 = () => interopDefault(import('../pages/education-research/research/article-paper/set-note.vue' /* webpackChunkName: "pages/education-research/research/article-paper/set-note" */))
const _26810cae = () => interopDefault(import('../pages/education-research/research/database/main.vue' /* webpackChunkName: "pages/education-research/research/database/main" */))
const _0f6abb73 = () => interopDefault(import('../pages/education-research/research/market-report/ceo-survey.vue' /* webpackChunkName: "pages/education-research/research/market-report/ceo-survey" */))
const _6ef2123c = () => interopDefault(import('../pages/education-research/research/market-report/international-roundups.vue' /* webpackChunkName: "pages/education-research/research/market-report/international-roundups" */))
const _c675cab4 = () => interopDefault(import('../pages/education-research/research/market-report/investor-sentiment-index-cmri.vue' /* webpackChunkName: "pages/education-research/research/market-report/investor-sentiment-index-cmri" */))
const _2ffd3d92 = () => interopDefault(import('../pages/education-research/research/market-report/main.vue' /* webpackChunkName: "pages/education-research/research/market-report/main" */))
const _58dcb6e7 = () => interopDefault(import('../pages/education-research/research/market-report/market-highlights.vue' /* webpackChunkName: "pages/education-research/research/market-report/market-highlights" */))
const _eb08bd2e = () => interopDefault(import('../pages/education-research/research/market-report/Oldslug.vue' /* webpackChunkName: "pages/education-research/research/market-report/Oldslug" */))
const _78acb971 = () => interopDefault(import('../pages/education-research/research/market-report/set-monthly-market-report.vue' /* webpackChunkName: "pages/education-research/research/market-report/set-monthly-market-report" */))
const _2a06b13c = () => interopDefault(import('../pages/education-research/research/research-grant/all-research-grant.vue' /* webpackChunkName: "pages/education-research/research/research-grant/all-research-grant" */))
const _4961248f = () => interopDefault(import('../pages/listing/ipo/upcoming-ipo/foreign-listing.vue' /* webpackChunkName: "pages/listing/ipo/upcoming-ipo/foreign-listing" */))
const _0c185eb3 = () => interopDefault(import('../pages/listing/ipo/upcoming-ipo/infrastructure-fund.vue' /* webpackChunkName: "pages/listing/ipo/upcoming-ipo/infrastructure-fund" */))
const _4a6e4c59 = () => interopDefault(import('../pages/listing/ipo/upcoming-ipo/mai.vue' /* webpackChunkName: "pages/listing/ipo/upcoming-ipo/mai" */))
const _3bd8766c = () => interopDefault(import('../pages/listing/ipo/upcoming-ipo/reit.vue' /* webpackChunkName: "pages/listing/ipo/upcoming-ipo/reit" */))
const _e17bd634 = () => interopDefault(import('../pages/listing/ipo/upcoming-ipo/set.vue' /* webpackChunkName: "pages/listing/ipo/upcoming-ipo/set" */))
const _310ef50d = () => interopDefault(import('../pages/market/indexs/gms-exchanges/trading.vue' /* webpackChunkName: "pages/market/indexs/gms-exchanges/trading" */))
const _2d29bf54 = () => interopDefault(import('../pages/market/information/member-list/main/index.vue' /* webpackChunkName: "pages/market/information/member-list/main/index" */))
const _74edc59a = () => interopDefault(import('../pages/market/information/securities-list/concessions-list.vue' /* webpackChunkName: "pages/market/information/securities-list/concessions-list" */))
const _21e72f52 = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/article.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/article" */))
const _5c6e182a = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/ebook.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/ebook" */))
const _34a9715f = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/elearning.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/elearning" */))
const _66cf4cd6 = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/infographic.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/infographic" */))
const _0afaaf8d = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/main.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/main" */))
const _2447a340 = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/podcast.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/podcast" */))
const _3bfa0835 = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/podcastplaylist/index.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/podcastplaylist/index" */))
const _9535e552 = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/video.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/video" */))
const _2e59eaec = () => interopDefault(import('../pages/education-research/education/happymoney/knowledge/videoplaylist/index.vue' /* webpackChunkName: "pages/education-research/education/happymoney/knowledge/videoplaylist/index" */))
const _bd649b94 = () => interopDefault(import('../pages/tsd/services/investors/calculators/right-offering.vue' /* webpackChunkName: "pages/tsd/services/investors/calculators/right-offering" */))
const _9571f236 = () => interopDefault(import('../pages/tsd/services/investors/calculators/stock-dividend.vue' /* webpackChunkName: "pages/tsd/services/investors/calculators/stock-dividend" */))
const _c199550a = () => interopDefault(import('../pages/tsd/services/investors/transaction/certificate/certificate-loss-list/index.vue' /* webpackChunkName: "pages/tsd/services/investors/transaction/certificate/certificate-loss-list/index" */))
const _a211ddf4 = () => interopDefault(import('../pages/tsd/services/investors/transaction/certificate/name-changes-record/index.vue' /* webpackChunkName: "pages/tsd/services/investors/transaction/certificate/name-changes-record/index" */))
const _555bd7bc = () => interopDefault(import('../pages/education-research/education/happymoney/glossary/_slug.vue' /* webpackChunkName: "pages/education-research/education/happymoney/glossary/_slug" */))
const _32929c5c = () => interopDefault(import('../pages/market/product/dr/quote/_slug/index.vue' /* webpackChunkName: "pages/market/product/dr/quote/_slug/index" */))
const _2065be0c = () => interopDefault(import('../pages/market/product/drx/quote/_slug/index.vue' /* webpackChunkName: "pages/market/product/drx/quote/_slug/index" */))
const _59aeb017 = () => interopDefault(import('../pages/market/product/dw/quote/_slug/index.vue' /* webpackChunkName: "pages/market/product/dw/quote/_slug/index" */))
const _651c6239 = () => interopDefault(import('../pages/market/product/etf/quote/_slug/index.vue' /* webpackChunkName: "pages/market/product/etf/quote/_slug/index" */))
const _2060ea90 = () => interopDefault(import('../pages/market/product/stock/quote/_slug/index.vue' /* webpackChunkName: "pages/market/product/stock/quote/_slug/index" */))
const _113ef21a = () => interopDefault(import('../pages/market/product/dr/quote/_slug/factsheet.vue' /* webpackChunkName: "pages/market/product/dr/quote/_slug/factsheet" */))
const _15f5dab8 = () => interopDefault(import('../pages/market/product/dw/quote/_slug/factsheet.vue' /* webpackChunkName: "pages/market/product/dw/quote/_slug/factsheet" */))
const _7119abda = () => interopDefault(import('../pages/market/product/etf/quote/_slug/factsheet.vue' /* webpackChunkName: "pages/market/product/etf/quote/_slug/factsheet" */))
const _b613064e = () => interopDefault(import('../pages/market/product/stock/quote/_slug/factsheet.vue' /* webpackChunkName: "pages/market/product/stock/quote/_slug/factsheet" */))
const _460a2242 = () => interopDefault(import('../pages/market/product/dr/quote/_slug/_.vue' /* webpackChunkName: "pages/market/product/dr/quote/_slug/_" */))
const _3d85afce = () => interopDefault(import('../pages/market/product/drx/quote/_slug/_.vue' /* webpackChunkName: "pages/market/product/drx/quote/_slug/_" */))
const _3d6250b8 = () => interopDefault(import('../pages/market/product/dw/quote/_slug/_.vue' /* webpackChunkName: "pages/market/product/dw/quote/_slug/_" */))
const _471eeac6 = () => interopDefault(import('../pages/market/product/etf/quote/_slug/_.vue' /* webpackChunkName: "pages/market/product/etf/quote/_slug/_" */))
const _36ffaa76 = () => interopDefault(import('../pages/market/product/stock/quote/_slug/_.vue' /* webpackChunkName: "pages/market/product/stock/quote/_slug/_" */))
const _4e6eb7a3 = () => interopDefault(import('../pages/market/information/member-list/_slug/index.vue' /* webpackChunkName: "pages/market/information/member-list/_slug/index" */))
const _efea6d3a = () => interopDefault(import('../pages/education-research/research/database/_slug/examples.vue' /* webpackChunkName: "pages/education-research/research/database/_slug/examples" */))
const _0a3e2e18 = () => interopDefault(import('../pages/market/indexs/_slug/index.vue' /* webpackChunkName: "pages/market/indexs/_slug/index" */))
const _e14f2dba = () => interopDefault(import('../pages/market/indexs/_slug/overview.vue' /* webpackChunkName: "pages/market/indexs/_slug/overview" */))
const _721dfc46 = () => interopDefault(import('../pages/market/indexs/_slug/_industry/index.vue' /* webpackChunkName: "pages/market/indexs/_slug/_industry/index" */))
const _2fa3573c = () => interopDefault(import('../pages/market/indexs/_slug/_industry/_sector/index.vue' /* webpackChunkName: "pages/market/indexs/_slug/_industry/_sector/index" */))
const _100365e4 = () => interopDefault(import('../pages/preview-form/_slug.vue' /* webpackChunkName: "pages/preview-form/_slug" */))
const _77e72944 = () => interopDefault(import('../pages/preview/_.vue' /* webpackChunkName: "pages/preview/_" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'link-active',
  linkExactActiveClass: 'link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/th",
    component: _2dfb1658,
    name: "index___th"
  }, {
    path: "/en/authorization",
    component: _4b385702,
    name: "authorization___en"
  }, {
    path: "/en/event-tracking-report",
    component: _5c093add,
    name: "event-tracking-report___en"
  }, {
    path: "/en/health-check",
    component: _6346d9c1,
    name: "health-check___en"
  }, {
    path: "/en/home",
    component: _7260f649,
    name: "home___en"
  }, {
    path: "/en/market",
    component: _c62e76ae,
    name: "market___en"
  }, {
    path: "/en/member",
    component: _0e9cdd87,
    children: [{
      path: "",
      component: _63f8e2ca,
      name: "member-index___en"
    }, {
      path: "favorites",
      component: _2b70ac62,
      name: "member-index-favorites___en"
    }, {
      path: "my-profile",
      component: _60a9af43,
      children: [{
        path: "",
        component: _670f10f4,
        name: "member-index-my-profile-index___en"
      }, {
        path: "check-registration-status",
        component: _2b7d5875,
        name: "member-index-my-profile-index-check-registration-status___en"
      }, {
        path: "events",
        component: _5734a4d6,
        name: "member-index-my-profile-index-events___en"
      }, {
        path: "examination",
        component: _f73493b6,
        name: "member-index-my-profile-index-examination___en"
      }, {
        path: "main",
        component: _e8946996,
        name: "member-index-my-profile-index-main___en"
      }, {
        path: "past-training-examination",
        component: _594430e6,
        name: "member-index-my-profile-index-past-training-examination___en"
      }, {
        path: "set-member-privilege",
        component: _2f3cfac5,
        name: "member-index-my-profile-index-set-member-privilege___en"
      }, {
        path: "training",
        component: _b1eff854,
        name: "member-index-my-profile-index-training___en"
      }]
    }, {
      path: "my-profile/:vue?",
      component: _d1443834,
      name: "member-index-my-profile-vue___en"
    }]
  }, {
    path: "/en/redirect",
    component: _039c8c74,
    name: "redirect___en"
  }, {
    path: "/en/rules-regulations",
    component: _3aff41fc,
    children: [{
      path: "",
      component: _5b395b7f,
      name: "rules-regulations-index___en"
    }, {
      path: "circulars",
      component: _a1e7a63e,
      name: "rules-regulations-index-circulars___en"
    }, {
      path: "forms",
      component: _0f766f48,
      name: "rules-regulations-index-forms___en"
    }, {
      path: "regulations",
      component: _5c3f6576,
      name: "rules-regulations-index-regulations___en"
    }, {
      path: "search-result",
      component: _0865e58f,
      name: "rules-regulations-index-search-result___en"
    }, {
      path: "update",
      component: _dd046422,
      name: "rules-regulations-index-update___en"
    }, {
      path: "update/circulars",
      component: _6ff1e751,
      name: "rules-regulations-index-update-circulars___en"
    }, {
      path: "update/forms",
      component: _2e893acc,
      name: "rules-regulations-index-update-forms___en"
    }, {
      path: "update/regulations",
      component: _7aa043e6,
      name: "rules-regulations-index-update-regulations___en"
    }]
  }, {
    path: "/en/search-result",
    component: _1e609130,
    name: "search-result___en"
  }, {
    path: "/th/authorization",
    component: _4b385702,
    name: "authorization___th"
  }, {
    path: "/th/event-tracking-report",
    component: _5c093add,
    name: "event-tracking-report___th"
  }, {
    path: "/th/health-check",
    component: _6346d9c1,
    name: "health-check___th"
  }, {
    path: "/th/home",
    component: _7260f649,
    name: "home___th"
  }, {
    path: "/th/market",
    component: _c62e76ae,
    name: "market___th"
  }, {
    path: "/th/member",
    component: _0e9cdd87,
    children: [{
      path: "",
      component: _63f8e2ca,
      name: "member-index___th"
    }, {
      path: "favorites",
      component: _2b70ac62,
      name: "member-index-favorites___th"
    }, {
      path: "my-profile",
      component: _60a9af43,
      children: [{
        path: "",
        component: _670f10f4,
        name: "member-index-my-profile-index___th"
      }, {
        path: "check-registration-status",
        component: _2b7d5875,
        name: "member-index-my-profile-index-check-registration-status___th"
      }, {
        path: "events",
        component: _5734a4d6,
        name: "member-index-my-profile-index-events___th"
      }, {
        path: "examination",
        component: _f73493b6,
        name: "member-index-my-profile-index-examination___th"
      }, {
        path: "main",
        component: _e8946996,
        name: "member-index-my-profile-index-main___th"
      }, {
        path: "past-training-examination",
        component: _594430e6,
        name: "member-index-my-profile-index-past-training-examination___th"
      }, {
        path: "set-member-privilege",
        component: _2f3cfac5,
        name: "member-index-my-profile-index-set-member-privilege___th"
      }, {
        path: "training",
        component: _b1eff854,
        name: "member-index-my-profile-index-training___th"
      }]
    }, {
      path: "my-profile/:vue?",
      component: _d1443834,
      name: "member-index-my-profile-vue___th"
    }]
  }, {
    path: "/th/redirect",
    component: _039c8c74,
    name: "redirect___th"
  }, {
    path: "/th/rules-regulations",
    component: _3aff41fc,
    children: [{
      path: "",
      component: _5b395b7f,
      name: "rules-regulations-index___th"
    }, {
      path: "circulars",
      component: _a1e7a63e,
      name: "rules-regulations-index-circulars___th"
    }, {
      path: "forms",
      component: _0f766f48,
      name: "rules-regulations-index-forms___th"
    }, {
      path: "regulations",
      component: _5c3f6576,
      name: "rules-regulations-index-regulations___th"
    }, {
      path: "search-result",
      component: _0865e58f,
      name: "rules-regulations-index-search-result___th"
    }, {
      path: "update",
      component: _dd046422,
      name: "rules-regulations-index-update___th"
    }, {
      path: "update/circulars",
      component: _6ff1e751,
      name: "rules-regulations-index-update-circulars___th"
    }, {
      path: "update/forms",
      component: _2e893acc,
      name: "rules-regulations-index-update-forms___th"
    }, {
      path: "update/regulations",
      component: _7aa043e6,
      name: "rules-regulations-index-update-regulations___th"
    }]
  }, {
    path: "/th/search-result",
    component: _1e609130,
    name: "search-result___th"
  }, {
    path: "/en/about/event-calendar",
    component: _7e807400,
    name: "about-event-calendar___en"
  }, {
    path: "/en/about/setsource",
    component: _a7f31f78,
    name: "about-setsource___en"
  }, {
    path: "/en/education-research/research",
    component: _69afeb9a,
    name: "education-research-research___en"
  }, {
    path: "/en/error/404",
    component: _4bf81892,
    name: "error-404___en"
  }, {
    path: "/en/error/500",
    component: _0eb1d0f4,
    name: "error-500___en"
  }, {
    path: "/en/error/503",
    component: _0edc1777,
    name: "error-503___en"
  }, {
    path: "/en/error/oldversion",
    component: _3d809dbc,
    name: "error-oldversion___en"
  }, {
    path: "/en/listing/ipo",
    component: _7277f3e6,
    name: "listing-ipo___en"
  }, {
    path: "/en/market/get-quote",
    component: _78827d9f,
    name: "market-get-quote___en"
  }, {
    path: "/en/market/index",
    component: _0be246c6,
    name: "market-index___en"
  }, {
    path: "/en/market/news-and-alert",
    component: _67ecb930,
    children: [{
      path: "",
      component: _0a57fbeb,
      name: "market-news-and-alert-index___en"
    }, {
      path: "auditors-opinion",
      component: _5af15c61,
      name: "market-news-and-alert-index-auditors-opinion___en"
    }, {
      path: "cashbalance-c-summary",
      component: _50e8a5d1,
      name: "market-news-and-alert-index-cashbalance-c-summary___en"
    }, {
      path: "dynamic-price-band",
      component: _103311be,
      name: "market-news-and-alert-index-dynamic-price-band___en"
    }, {
      path: "market-alerts",
      component: _3af01461,
      name: "market-news-and-alert-index-market-alerts___en"
    }, {
      path: "news",
      component: _54f60e4a,
      name: "market-news-and-alert-index-news___en"
    }, {
      path: "sign-posting",
      component: _0490dc29,
      name: "market-news-and-alert-index-sign-posting___en"
    }, {
      path: "surveillance-c-sign-temporary-trading",
      component: _11b0758c,
      name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading___en",
      children: [{
        path: "c-sign",
        component: _37cf3ffa,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-c-sign___en"
      }, {
        path: "market-surveillance-measure-list",
        component: _8281a23a,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-market-surveillance-measure-list___en"
      }, {
        path: "summary",
        component: _8f37faba,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-summary___en"
      }, {
        path: "temporary-trading",
        component: _57b0cee2,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-temporary-trading___en"
      }]
    }, {
      path: "trading-halt-suspension-pause",
      component: _cf78ab02,
      name: "market-news-and-alert-index-trading-halt-suspension-pause___en"
    }]
  }, {
    path: "/en/market/statistics",
    component: _34fb075c,
    children: [{
      path: "",
      component: _a238ce42,
      name: "market-statistics___en"
    }, {
      path: "five-days",
      component: _c01e6fc2,
      name: "market-statistics-five-days___en"
    }, {
      path: "investor-type",
      component: _22636fdc,
      name: "market-statistics-investor-type___en"
    }, {
      path: "nvdr",
      component: _64ce724e,
      children: [{
        path: "",
        component: _3861069c,
        name: "market-statistics-nvdr___en"
      }, {
        path: "main",
        component: _1b94015f,
        name: "market-statistics-nvdr-main___en"
      }, {
        path: "outstanding-share",
        component: _9373e894,
        name: "market-statistics-nvdr-outstanding-share___en"
      }, {
        path: "trading-by-method",
        component: _65ade096,
        name: "market-statistics-nvdr-trading-by-method___en"
      }, {
        path: "trading-by-sector",
        component: _1c7dcbba,
        name: "market-statistics-nvdr-trading-by-sector___en"
      }, {
        path: "trading-by-stock",
        component: _4b44c272,
        name: "market-statistics-nvdr-trading-by-stock___en"
      }]
    }, {
      path: "program-trading-value",
      component: _09cdc749,
      name: "market-statistics-program-trading-value___en"
    }, {
      path: "short-sales",
      component: _27dccccb,
      children: [{
        path: "",
        component: _50714f0e,
        name: "market-statistics-short-sales-index___en"
      }, {
        path: "outstanding-short-positions",
        component: _474a5b62,
        name: "market-statistics-short-sales-index-outstanding-short-positions___en"
      }, {
        path: "total-short-sales",
        component: _53e888a4,
        name: "market-statistics-short-sales-index-total-short-sales___en"
      }]
    }, {
      path: "short-sell",
      component: _4a841dc6,
      name: "market-statistics-short-sell___en"
    }, {
      path: "market-statistics/capital-raise",
      component: _97a572ce,
      name: "market-statistics-market-statistics-capital-raise___en"
    }, {
      path: "market-statistics/ipo",
      component: _395f577a,
      name: "market-statistics-market-statistics-ipo___en"
    }, {
      path: "market-statistics/monthly-report/:slug?",
      component: _15cef366,
      name: "market-statistics-market-statistics-monthly-report-slug___en"
    }]
  }, {
    path: "/en/market/stock-calendar",
    component: _148668d1,
    name: "market-stock-calendar___en"
  }, {
    path: "/en/rules-regulations/details",
    component: _241d9228,
    name: "rules-regulations-details___en"
  }, {
    path: "/en/rules-regulations/exchange-enquiries",
    component: _2e61f386,
    name: "rules-regulations-exchange-enquiries___en"
  }, {
    path: "/en/rules-regulations/regulatory-statistics",
    component: _6bcb42a5,
    name: "rules-regulations-regulatory-statistics___en"
  }, {
    path: "/en/rules-regulations/rules-canceled",
    component: _5c49ec75,
    name: "rules-regulations-rules-canceled___en"
  }, {
    path: "/en/tch/overview",
    component: _3e575a59,
    name: "tch-overview___en"
  }, {
    path: "/en/tch/rules-regulations",
    component: _520cb474,
    children: [{
      path: "",
      component: _5a483fc9,
      name: "tch-rules-regulations-index___en"
    }, {
      path: "circulars",
      component: _081bdc2b,
      name: "tch-rules-regulations-index-circulars___en"
    }, {
      path: "forms",
      component: _1158a6b4,
      name: "tch-rules-regulations-index-forms___en"
    }, {
      path: "regulations",
      component: _9fbf2b80,
      name: "tch-rules-regulations-index-regulations___en"
    }]
  }, {
    path: "/en/tsd/overview",
    component: _5496d7ed,
    name: "tsd-overview___en"
  }, {
    path: "/en/tsd/rules-regulations",
    component: _3c84cdb2,
    children: [{
      path: "",
      component: _02d8c6b5,
      name: "tsd-rules-regulations-index___en"
    }, {
      path: "circulars",
      component: _55f70d17,
      name: "tsd-rules-regulations-index-circulars___en"
    }, {
      path: "forms",
      component: _c03798dc,
      name: "tsd-rules-regulations-index-forms___en"
    }, {
      path: "regulations",
      component: _1819dfa8,
      name: "tsd-rules-regulations-index-regulations___en"
    }]
  }, {
    path: "/th/about/event-calendar",
    component: _7e807400,
    name: "about-event-calendar___th"
  }, {
    path: "/th/about/setsource",
    component: _a7f31f78,
    name: "about-setsource___th"
  }, {
    path: "/th/education-research/research",
    component: _69afeb9a,
    name: "education-research-research___th"
  }, {
    path: "/th/error/404",
    component: _4bf81892,
    name: "error-404___th"
  }, {
    path: "/th/error/500",
    component: _0eb1d0f4,
    name: "error-500___th"
  }, {
    path: "/th/error/503",
    component: _0edc1777,
    name: "error-503___th"
  }, {
    path: "/th/error/oldversion",
    component: _3d809dbc,
    name: "error-oldversion___th"
  }, {
    path: "/th/listing/ipo",
    component: _7277f3e6,
    name: "listing-ipo___th"
  }, {
    path: "/th/market/get-quote",
    component: _78827d9f,
    name: "market-get-quote___th"
  }, {
    path: "/th/market/index",
    component: _0be246c6,
    name: "market-index___th"
  }, {
    path: "/th/market/news-and-alert",
    component: _67ecb930,
    children: [{
      path: "",
      component: _0a57fbeb,
      name: "market-news-and-alert-index___th"
    }, {
      path: "auditors-opinion",
      component: _5af15c61,
      name: "market-news-and-alert-index-auditors-opinion___th"
    }, {
      path: "cashbalance-c-summary",
      component: _50e8a5d1,
      name: "market-news-and-alert-index-cashbalance-c-summary___th"
    }, {
      path: "dynamic-price-band",
      component: _103311be,
      name: "market-news-and-alert-index-dynamic-price-band___th"
    }, {
      path: "market-alerts",
      component: _3af01461,
      name: "market-news-and-alert-index-market-alerts___th"
    }, {
      path: "news",
      component: _54f60e4a,
      name: "market-news-and-alert-index-news___th"
    }, {
      path: "sign-posting",
      component: _0490dc29,
      name: "market-news-and-alert-index-sign-posting___th"
    }, {
      path: "surveillance-c-sign-temporary-trading",
      component: _11b0758c,
      name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading___th",
      children: [{
        path: "c-sign",
        component: _37cf3ffa,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-c-sign___th"
      }, {
        path: "market-surveillance-measure-list",
        component: _8281a23a,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-market-surveillance-measure-list___th"
      }, {
        path: "summary",
        component: _8f37faba,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-summary___th"
      }, {
        path: "temporary-trading",
        component: _57b0cee2,
        name: "market-news-and-alert-index-surveillance-c-sign-temporary-trading-temporary-trading___th"
      }]
    }, {
      path: "trading-halt-suspension-pause",
      component: _cf78ab02,
      name: "market-news-and-alert-index-trading-halt-suspension-pause___th"
    }]
  }, {
    path: "/th/market/statistics",
    component: _34fb075c,
    children: [{
      path: "",
      component: _a238ce42,
      name: "market-statistics___th"
    }, {
      path: "five-days",
      component: _c01e6fc2,
      name: "market-statistics-five-days___th"
    }, {
      path: "investor-type",
      component: _22636fdc,
      name: "market-statistics-investor-type___th"
    }, {
      path: "nvdr",
      component: _64ce724e,
      children: [{
        path: "",
        component: _3861069c,
        name: "market-statistics-nvdr___th"
      }, {
        path: "main",
        component: _1b94015f,
        name: "market-statistics-nvdr-main___th"
      }, {
        path: "outstanding-share",
        component: _9373e894,
        name: "market-statistics-nvdr-outstanding-share___th"
      }, {
        path: "trading-by-method",
        component: _65ade096,
        name: "market-statistics-nvdr-trading-by-method___th"
      }, {
        path: "trading-by-sector",
        component: _1c7dcbba,
        name: "market-statistics-nvdr-trading-by-sector___th"
      }, {
        path: "trading-by-stock",
        component: _4b44c272,
        name: "market-statistics-nvdr-trading-by-stock___th"
      }]
    }, {
      path: "program-trading-value",
      component: _09cdc749,
      name: "market-statistics-program-trading-value___th"
    }, {
      path: "short-sales",
      component: _27dccccb,
      children: [{
        path: "",
        component: _50714f0e,
        name: "market-statistics-short-sales-index___th"
      }, {
        path: "outstanding-short-positions",
        component: _474a5b62,
        name: "market-statistics-short-sales-index-outstanding-short-positions___th"
      }, {
        path: "total-short-sales",
        component: _53e888a4,
        name: "market-statistics-short-sales-index-total-short-sales___th"
      }]
    }, {
      path: "short-sell",
      component: _4a841dc6,
      name: "market-statistics-short-sell___th"
    }, {
      path: "market-statistics/capital-raise",
      component: _97a572ce,
      name: "market-statistics-market-statistics-capital-raise___th"
    }, {
      path: "market-statistics/ipo",
      component: _395f577a,
      name: "market-statistics-market-statistics-ipo___th"
    }, {
      path: "market-statistics/monthly-report/:slug?",
      component: _15cef366,
      name: "market-statistics-market-statistics-monthly-report-slug___th"
    }]
  }, {
    path: "/th/market/stock-calendar",
    component: _148668d1,
    name: "market-stock-calendar___th"
  }, {
    path: "/th/rules-regulations/details",
    component: _241d9228,
    name: "rules-regulations-details___th"
  }, {
    path: "/th/rules-regulations/exchange-enquiries",
    component: _2e61f386,
    name: "rules-regulations-exchange-enquiries___th"
  }, {
    path: "/th/rules-regulations/regulatory-statistics",
    component: _6bcb42a5,
    name: "rules-regulations-regulatory-statistics___th"
  }, {
    path: "/th/rules-regulations/rules-canceled",
    component: _5c49ec75,
    name: "rules-regulations-rules-canceled___th"
  }, {
    path: "/th/tch/overview",
    component: _3e575a59,
    name: "tch-overview___th"
  }, {
    path: "/th/tch/rules-regulations",
    component: _520cb474,
    children: [{
      path: "",
      component: _5a483fc9,
      name: "tch-rules-regulations-index___th"
    }, {
      path: "circulars",
      component: _081bdc2b,
      name: "tch-rules-regulations-index-circulars___th"
    }, {
      path: "forms",
      component: _1158a6b4,
      name: "tch-rules-regulations-index-forms___th"
    }, {
      path: "regulations",
      component: _9fbf2b80,
      name: "tch-rules-regulations-index-regulations___th"
    }]
  }, {
    path: "/th/tsd/overview",
    component: _5496d7ed,
    name: "tsd-overview___th"
  }, {
    path: "/th/tsd/rules-regulations",
    component: _3c84cdb2,
    children: [{
      path: "",
      component: _02d8c6b5,
      name: "tsd-rules-regulations-index___th"
    }, {
      path: "circulars",
      component: _55f70d17,
      name: "tsd-rules-regulations-index-circulars___th"
    }, {
      path: "forms",
      component: _c03798dc,
      name: "tsd-rules-regulations-index-forms___th"
    }, {
      path: "regulations",
      component: _1819dfa8,
      name: "tsd-rules-regulations-index-regulations___th"
    }]
  }, {
    path: "/en/about/event-calendar/event",
    component: _e654ab6a,
    name: "about-event-calendar-event___en"
  }, {
    path: "/en/about/event-calendar/holiday",
    component: _935599f4,
    name: "about-event-calendar-holiday___en"
  }, {
    path: "/en/about/join-us/main",
    component: _489e8113,
    name: "about-join-us-main___en"
  }, {
    path: "/en/about/setsource/insights",
    component: _37caf09c,
    name: "about-setsource-insights___en"
  }, {
    path: "/en/about/setsource/news-release",
    component: _235ad24e,
    name: "about-setsource-news-release___en"
  }, {
    path: "/en/about/setsource/search-result",
    component: _a4ef3558,
    name: "about-setsource-search-result___en"
  }, {
    path: "/en/about/setsource/setsource-vdo",
    component: _5d960ea3,
    name: "about-setsource-setsource-vdo___en"
  }, {
    path: "/en/about/setsource/yaek-yae",
    component: _8c6f9a82,
    name: "about-setsource-yaek-yae___en"
  }, {
    path: "/en/education-research/education/setbook",
    component: _5a9b0b3c,
    name: "education-research-education-setbook___en",
    children: [{
      path: "pocket-book/main",
      component: _57d3b065,
      name: "education-research-education-setbook-index-pocket-book-main___en"
    }, {
      path: "text-book/main",
      component: _0c60de08,
      name: "education-research-education-setbook-index-text-book-main___en"
    }]
  }, {
    path: "/en/education-research/research/article-paper",
    component: _ec9aa24e,
    name: "education-research-research-article-paper___en"
  }, {
    path: "/en/education-research/research/market-report",
    component: _9dc15e6e,
    name: "education-research-research-market-report___en"
  }, {
    path: "/en/education-research/research/overview",
    component: _1ddd5328,
    name: "education-research-research-overview___en"
  }, {
    path: "/en/education-research/research/search-result",
    component: _6de4a043,
    name: "education-research-research-search-result___en"
  }, {
    path: "/en/listing/ipo/first-trading-day",
    component: _4189a72a,
    name: "listing-ipo-first-trading-day___en"
  }, {
    path: "/en/listing/ipo/ipo-statistics",
    component: _05970807,
    name: "listing-ipo-ipo-statistics___en"
  }, {
    path: "/en/listing/ipo/upcoming-ipo",
    component: _9a7c8354,
    name: "listing-ipo-upcoming-ipo___en"
  }, {
    path: "/en/listing/other-instruments/dw",
    component: _21faaf8b,
    children: [{
      path: "",
      component: _6dca21ce,
      name: "listing-other-instruments-dw-index___en"
    }, {
      path: "eligible-underlying",
      component: _4610ee8f,
      name: "listing-other-instruments-dw-index-eligible-underlying___en"
    }, {
      path: "issuers",
      component: _c4135e54,
      name: "listing-other-instruments-dw-index-issuers___en"
    }, {
      path: "outstanding-report",
      component: _11987afb,
      name: "listing-other-instruments-dw-index-outstanding-report___en"
    }]
  }, {
    path: "/en/market/get-quote/dr",
    component: _7db41258,
    children: [{
      path: "",
      component: _9e69e352,
      name: "market-get-quote-dr-index___en"
    }]
  }, {
    path: "/en/market/get-quote/drx",
    component: _584198fa,
    children: [{
      path: "",
      component: _595c0806,
      name: "market-get-quote-drx-index___en"
    }]
  }, {
    path: "/en/market/get-quote/dw",
    component: _acc516ce,
    children: [{
      path: "",
      component: _441c645c,
      name: "market-get-quote-dw-index___en"
    }]
  }, {
    path: "/en/market/get-quote/etf",
    component: _43d94eb2,
    children: [{
      path: "",
      component: _7e2e85ea,
      name: "market-get-quote-etf-index___en"
    }]
  }, {
    path: "/en/market/get-quote/preferred-shares",
    component: _d6be5874,
    children: [{
      path: "",
      component: _5d54edc9,
      name: "market-get-quote-preferred-shares-index___en"
    }]
  }, {
    path: "/en/market/get-quote/stock",
    component: _5721da26,
    children: [{
      path: "",
      component: _9260a7ae,
      name: "market-get-quote-stock-index___en"
    }]
  }, {
    path: "/en/market/get-quote/warrants",
    component: _fbf1c608,
    children: [{
      path: "",
      component: _149e1302,
      name: "market-get-quote-warrants-index___en"
    }]
  }, {
    path: "/en/market/information/member-list",
    component: _acc7ab30,
    name: "market-information-member-list___en"
  }, {
    path: "/en/market/news-and-alert/market-clarification",
    component: _195f69cd,
    name: "market-news-and-alert-market-clarification___en"
  }, {
    path: "/en/market/news-and-alert/newsdetails",
    component: _0c9181a5,
    name: "market-news-and-alert-newsdetails___en"
  }, {
    path: "/en/market/product/dr",
    component: _70430baa,
    children: [{
      path: "",
      component: _0b3eaaad,
      name: "market-product-dr-index___en"
    }, {
      path: "information",
      component: _cff88872,
      name: "market-product-dr-index-information___en"
    }, {
      path: "overview",
      component: _26fa286e,
      name: "market-product-dr-index-overview___en"
    }]
  }, {
    path: "/en/market/product/drx",
    component: _e671c238,
    children: [{
      path: "",
      component: _715c2b32,
      name: "market-product-drx-index___en"
    }, {
      path: "marketdata",
      component: _e523f8be,
      name: "market-product-drx-index-marketdata___en"
    }, {
      path: "news",
      component: _3a06604e,
      name: "market-product-drx-index-news___en"
    }, {
      path: "overview",
      component: _aa01d318,
      name: "market-product-drx-index-overview___en"
    }]
  }, {
    path: "/en/market/product/dw",
    component: _58ba896f,
    children: [{
      path: "",
      component: _c2dffe9c,
      name: "market-product-dw-index___en"
    }, {
      path: "overview",
      component: _a5b94f6e,
      name: "market-product-dw-index-overview___en"
    }, {
      path: "search",
      component: _bacb490a,
      name: "market-product-dw-index-search___en"
    }, {
      path: "search/:slug?",
      component: _be388b9a,
      name: "market-product-dw-index-search-slug___en"
    }]
  }, {
    path: "/en/market/product/etf",
    component: _1298de91,
    children: [{
      path: "",
      component: _722e7454,
      name: "market-product-etf-index___en"
    }, {
      path: "marketdata",
      component: _af28b458,
      name: "market-product-etf-index-marketdata___en"
    }, {
      path: "overview",
      component: _0155a967,
      name: "market-product-etf-index-overview___en"
    }]
  }, {
    path: "/en/market/product/stock",
    component: _ff44e4e0,
    children: [{
      path: "",
      component: _aae4afda,
      name: "market-product-stock-index___en"
    }, {
      path: "overview",
      component: _101c6948,
      name: "market-product-stock-index-overview___en"
    }, {
      path: "search",
      component: _52e633b7,
      name: "market-product-stock-index-search___en"
    }, {
      path: "top-ranking",
      component: _1fbfd23f,
      name: "market-product-stock-index-top-ranking___en"
    }]
  }, {
    path: "/en/market/stock-calendar/x-calendar",
    component: _bc63aff8,
    name: "market-stock-calendar-x-calendar___en"
  }, {
    path: "/en/tsd/information/company-list",
    component: _e2cdd336,
    name: "tsd-information-company-list___en"
  }, {
    path: "/en/tsd/information/debenture",
    component: _495d2b8c,
    name: "tsd-information-debenture___en"
  }, {
    path: "/en/tsd/information/e-proxy-voting",
    component: _bf2c76f6,
    name: "tsd-information-e-proxy-voting___en"
  }, {
    path: "/en/tsd/information/isin-cfi-fisn",
    component: _2cd821bc,
    name: "tsd-information-isin-cfi-fisn___en"
  }, {
    path: "/th/about/event-calendar/event",
    component: _e654ab6a,
    name: "about-event-calendar-event___th"
  }, {
    path: "/th/about/event-calendar/holiday",
    component: _935599f4,
    name: "about-event-calendar-holiday___th"
  }, {
    path: "/th/about/join-us/main",
    component: _489e8113,
    name: "about-join-us-main___th"
  }, {
    path: "/th/about/setsource/insights",
    component: _37caf09c,
    name: "about-setsource-insights___th"
  }, {
    path: "/th/about/setsource/news-release",
    component: _235ad24e,
    name: "about-setsource-news-release___th"
  }, {
    path: "/th/about/setsource/search-result",
    component: _a4ef3558,
    name: "about-setsource-search-result___th"
  }, {
    path: "/th/about/setsource/setsource-vdo",
    component: _5d960ea3,
    name: "about-setsource-setsource-vdo___th"
  }, {
    path: "/th/about/setsource/yaek-yae",
    component: _8c6f9a82,
    name: "about-setsource-yaek-yae___th"
  }, {
    path: "/th/education-research/education/setbook",
    component: _5a9b0b3c,
    name: "education-research-education-setbook___th",
    children: [{
      path: "pocket-book/main",
      component: _57d3b065,
      name: "education-research-education-setbook-index-pocket-book-main___th"
    }, {
      path: "text-book/main",
      component: _0c60de08,
      name: "education-research-education-setbook-index-text-book-main___th"
    }]
  }, {
    path: "/th/education-research/research/article-paper",
    component: _ec9aa24e,
    name: "education-research-research-article-paper___th"
  }, {
    path: "/th/education-research/research/market-report",
    component: _9dc15e6e,
    name: "education-research-research-market-report___th"
  }, {
    path: "/th/education-research/research/overview",
    component: _1ddd5328,
    name: "education-research-research-overview___th"
  }, {
    path: "/th/education-research/research/search-result",
    component: _6de4a043,
    name: "education-research-research-search-result___th"
  }, {
    path: "/th/listing/ipo/first-trading-day",
    component: _4189a72a,
    name: "listing-ipo-first-trading-day___th"
  }, {
    path: "/th/listing/ipo/ipo-statistics",
    component: _05970807,
    name: "listing-ipo-ipo-statistics___th"
  }, {
    path: "/th/listing/ipo/upcoming-ipo",
    component: _9a7c8354,
    name: "listing-ipo-upcoming-ipo___th"
  }, {
    path: "/th/listing/other-instruments/dw",
    component: _21faaf8b,
    children: [{
      path: "",
      component: _6dca21ce,
      name: "listing-other-instruments-dw-index___th"
    }, {
      path: "eligible-underlying",
      component: _4610ee8f,
      name: "listing-other-instruments-dw-index-eligible-underlying___th"
    }, {
      path: "issuers",
      component: _c4135e54,
      name: "listing-other-instruments-dw-index-issuers___th"
    }, {
      path: "outstanding-report",
      component: _11987afb,
      name: "listing-other-instruments-dw-index-outstanding-report___th"
    }]
  }, {
    path: "/th/market/get-quote/dr",
    component: _7db41258,
    children: [{
      path: "",
      component: _9e69e352,
      name: "market-get-quote-dr-index___th"
    }]
  }, {
    path: "/th/market/get-quote/drx",
    component: _584198fa,
    children: [{
      path: "",
      component: _595c0806,
      name: "market-get-quote-drx-index___th"
    }]
  }, {
    path: "/th/market/get-quote/dw",
    component: _acc516ce,
    children: [{
      path: "",
      component: _441c645c,
      name: "market-get-quote-dw-index___th"
    }]
  }, {
    path: "/th/market/get-quote/etf",
    component: _43d94eb2,
    children: [{
      path: "",
      component: _7e2e85ea,
      name: "market-get-quote-etf-index___th"
    }]
  }, {
    path: "/th/market/get-quote/preferred-shares",
    component: _d6be5874,
    children: [{
      path: "",
      component: _5d54edc9,
      name: "market-get-quote-preferred-shares-index___th"
    }]
  }, {
    path: "/th/market/get-quote/stock",
    component: _5721da26,
    children: [{
      path: "",
      component: _9260a7ae,
      name: "market-get-quote-stock-index___th"
    }]
  }, {
    path: "/th/market/get-quote/warrants",
    component: _fbf1c608,
    children: [{
      path: "",
      component: _149e1302,
      name: "market-get-quote-warrants-index___th"
    }]
  }, {
    path: "/th/market/information/member-list",
    component: _acc7ab30,
    name: "market-information-member-list___th"
  }, {
    path: "/th/market/news-and-alert/market-clarification",
    component: _195f69cd,
    name: "market-news-and-alert-market-clarification___th"
  }, {
    path: "/th/market/news-and-alert/newsdetails",
    component: _0c9181a5,
    name: "market-news-and-alert-newsdetails___th"
  }, {
    path: "/th/market/product/dr",
    component: _70430baa,
    children: [{
      path: "",
      component: _0b3eaaad,
      name: "market-product-dr-index___th"
    }, {
      path: "information",
      component: _cff88872,
      name: "market-product-dr-index-information___th"
    }, {
      path: "overview",
      component: _26fa286e,
      name: "market-product-dr-index-overview___th"
    }]
  }, {
    path: "/th/market/product/drx",
    component: _e671c238,
    children: [{
      path: "",
      component: _715c2b32,
      name: "market-product-drx-index___th"
    }, {
      path: "marketdata",
      component: _e523f8be,
      name: "market-product-drx-index-marketdata___th"
    }, {
      path: "news",
      component: _3a06604e,
      name: "market-product-drx-index-news___th"
    }, {
      path: "overview",
      component: _aa01d318,
      name: "market-product-drx-index-overview___th"
    }]
  }, {
    path: "/th/market/product/dw",
    component: _58ba896f,
    children: [{
      path: "",
      component: _c2dffe9c,
      name: "market-product-dw-index___th"
    }, {
      path: "overview",
      component: _a5b94f6e,
      name: "market-product-dw-index-overview___th"
    }, {
      path: "search",
      component: _bacb490a,
      name: "market-product-dw-index-search___th"
    }, {
      path: "search/:slug?",
      component: _be388b9a,
      name: "market-product-dw-index-search-slug___th"
    }]
  }, {
    path: "/th/market/product/etf",
    component: _1298de91,
    children: [{
      path: "",
      component: _722e7454,
      name: "market-product-etf-index___th"
    }, {
      path: "marketdata",
      component: _af28b458,
      name: "market-product-etf-index-marketdata___th"
    }, {
      path: "overview",
      component: _0155a967,
      name: "market-product-etf-index-overview___th"
    }]
  }, {
    path: "/th/market/product/stock",
    component: _ff44e4e0,
    children: [{
      path: "",
      component: _aae4afda,
      name: "market-product-stock-index___th"
    }, {
      path: "overview",
      component: _101c6948,
      name: "market-product-stock-index-overview___th"
    }, {
      path: "search",
      component: _52e633b7,
      name: "market-product-stock-index-search___th"
    }, {
      path: "top-ranking",
      component: _1fbfd23f,
      name: "market-product-stock-index-top-ranking___th"
    }]
  }, {
    path: "/th/market/stock-calendar/x-calendar",
    component: _bc63aff8,
    name: "market-stock-calendar-x-calendar___th"
  }, {
    path: "/th/tsd/information/company-list",
    component: _e2cdd336,
    name: "tsd-information-company-list___th"
  }, {
    path: "/th/tsd/information/debenture",
    component: _495d2b8c,
    name: "tsd-information-debenture___th"
  }, {
    path: "/th/tsd/information/e-proxy-voting",
    component: _bf2c76f6,
    name: "tsd-information-e-proxy-voting___th"
  }, {
    path: "/th/tsd/information/isin-cfi-fisn",
    component: _2cd821bc,
    name: "tsd-information-isin-cfi-fisn___th"
  }, {
    path: "/en/about/event-calendar/event/eventdetails",
    component: _1b4e85ff,
    name: "about-event-calendar-event-eventdetails___en"
  }, {
    path: "/en/about/event-calendar/event/overview",
    component: _ac52c7e0,
    name: "about-event-calendar-event-overview___en"
  }, {
    path: "/en/about/setsource/insights/article",
    component: _df11c380,
    name: "about-setsource-insights-article___en"
  }, {
    path: "/en/about/setsource/insights/ebook",
    component: _5e68ea98,
    name: "about-setsource-insights-ebook___en"
  }, {
    path: "/en/about/setsource/insights/elearning",
    component: _3ef95ccd,
    name: "about-setsource-insights-elearning___en"
  }, {
    path: "/en/about/setsource/insights/infographic",
    component: _1cd214c4,
    name: "about-setsource-insights-infographic___en"
  }, {
    path: "/en/about/setsource/insights/main",
    component: _1b765142,
    name: "about-setsource-insights-main___en"
  }, {
    path: "/en/about/setsource/insights/video",
    component: _91404076,
    name: "about-setsource-insights-video___en"
  }, {
    path: "/en/about/setsource/news-release/article",
    component: _50d5491c,
    name: "about-setsource-news-release-article___en"
  }, {
    path: "/en/about/setsource/news-release/ebook",
    component: _49f8cc4a,
    name: "about-setsource-news-release-ebook___en"
  }, {
    path: "/en/about/setsource/news-release/elearning",
    component: _377d157f,
    name: "about-setsource-news-release-elearning___en"
  }, {
    path: "/en/about/setsource/news-release/infographic",
    component: _034a68f6,
    name: "about-setsource-news-release-infographic___en"
  }, {
    path: "/en/about/setsource/news-release/main",
    component: _6538f76d,
    name: "about-setsource-news-release-main___en"
  }, {
    path: "/en/about/setsource/news-release/video",
    component: _ba207d12,
    name: "about-setsource-news-release-video___en"
  }, {
    path: "/en/about/setsource/yaek-yae/article",
    component: _efa738ba,
    name: "about-setsource-yaek-yae-article___en"
  }, {
    path: "/en/about/setsource/yaek-yae/ebook",
    component: _3f33a68a,
    name: "about-setsource-yaek-yae-ebook___en"
  }, {
    path: "/en/about/setsource/yaek-yae/elearning",
    component: _1e72d570,
    name: "about-setsource-yaek-yae-elearning___en"
  }, {
    path: "/en/about/setsource/yaek-yae/infographic",
    component: _03cff0a7,
    name: "about-setsource-yaek-yae-infographic___en"
  }, {
    path: "/en/about/setsource/yaek-yae/main",
    component: _725544dc,
    name: "about-setsource-yaek-yae-main___en"
  }, {
    path: "/en/about/setsource/yaek-yae/podcast",
    component: _542162b1,
    name: "about-setsource-yaek-yae-podcast___en"
  }, {
    path: "/en/about/setsource/yaek-yae/video",
    component: _395d21e8,
    name: "about-setsource-yaek-yae-video___en"
  }, {
    path: "/en/education-research/education/happymoney/glossary",
    component: _57127904,
    name: "education-research-education-happymoney-glossary___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge",
    component: _35d01e2e,
    name: "education-research-education-happymoney-knowledge___en"
  }, {
    path: "/en/education-research/education/happymoney/search-result",
    component: _45053262,
    name: "education-research-education-happymoney-search-result___en"
  }, {
    path: "/en/education-research/research/article-paper/main",
    component: _477ddd82,
    name: "education-research-research-article-paper-main___en"
  }, {
    path: "/en/education-research/research/article-paper/Oldslug",
    component: _e8a0890e,
    name: "education-research-research-article-paper-Oldslug___en"
  }, {
    path: "/en/education-research/research/article-paper/set-cmri-research-paper",
    component: _168927e2,
    name: "education-research-research-article-paper-set-cmri-research-paper___en"
  }, {
    path: "/en/education-research/research/article-paper/set-cmri-working-paper",
    component: _1d810918,
    name: "education-research-research-article-paper-set-cmri-working-paper___en"
  }, {
    path: "/en/education-research/research/article-paper/set-note",
    component: _525517c6,
    name: "education-research-research-article-paper-set-note___en"
  }, {
    path: "/en/education-research/research/database/main",
    component: _26810cae,
    name: "education-research-research-database-main___en"
  }, {
    path: "/en/education-research/research/market-report/ceo-survey",
    component: _0f6abb73,
    name: "education-research-research-market-report-ceo-survey___en"
  }, {
    path: "/en/education-research/research/market-report/international-roundups",
    component: _6ef2123c,
    name: "education-research-research-market-report-international-roundups___en"
  }, {
    path: "/en/education-research/research/market-report/investor-sentiment-index-cmri",
    component: _c675cab4,
    name: "education-research-research-market-report-investor-sentiment-index-cmri___en"
  }, {
    path: "/en/education-research/research/market-report/main",
    component: _2ffd3d92,
    name: "education-research-research-market-report-main___en"
  }, {
    path: "/en/education-research/research/market-report/market-highlights",
    component: _58dcb6e7,
    name: "education-research-research-market-report-market-highlights___en"
  }, {
    path: "/en/education-research/research/market-report/Oldslug",
    component: _eb08bd2e,
    name: "education-research-research-market-report-Oldslug___en"
  }, {
    path: "/en/education-research/research/market-report/set-monthly-market-report",
    component: _78acb971,
    name: "education-research-research-market-report-set-monthly-market-report___en"
  }, {
    path: "/en/education-research/research/research-grant/all-research-grant",
    component: _2a06b13c,
    name: "education-research-research-research-grant-all-research-grant___en"
  }, {
    path: "/en/listing/ipo/upcoming-ipo/foreign-listing",
    component: _4961248f,
    name: "listing-ipo-upcoming-ipo-foreign-listing___en"
  }, {
    path: "/en/listing/ipo/upcoming-ipo/infrastructure-fund",
    component: _0c185eb3,
    name: "listing-ipo-upcoming-ipo-infrastructure-fund___en"
  }, {
    path: "/en/listing/ipo/upcoming-ipo/mai",
    component: _4a6e4c59,
    name: "listing-ipo-upcoming-ipo-mai___en"
  }, {
    path: "/en/listing/ipo/upcoming-ipo/reit",
    component: _3bd8766c,
    name: "listing-ipo-upcoming-ipo-reit___en"
  }, {
    path: "/en/listing/ipo/upcoming-ipo/set",
    component: _e17bd634,
    name: "listing-ipo-upcoming-ipo-set___en"
  }, {
    path: "/en/market/index/gms-exchanges/trading",
    component: _310ef50d,
    name: "market-index-gms-exchanges-trading___en"
  }, {
    path: "/en/market/information/member-list/main",
    component: _2d29bf54,
    name: "market-information-member-list-main___en"
  }, {
    path: "/en/market/information/securities-list/concessions-list",
    component: _74edc59a,
    name: "market-information-securities-list-concessions-list___en"
  }, {
    path: "/th/about/event-calendar/event/eventdetails",
    component: _1b4e85ff,
    name: "about-event-calendar-event-eventdetails___th"
  }, {
    path: "/th/about/event-calendar/event/overview",
    component: _ac52c7e0,
    name: "about-event-calendar-event-overview___th"
  }, {
    path: "/th/about/setsource/insights/article",
    component: _df11c380,
    name: "about-setsource-insights-article___th"
  }, {
    path: "/th/about/setsource/insights/ebook",
    component: _5e68ea98,
    name: "about-setsource-insights-ebook___th"
  }, {
    path: "/th/about/setsource/insights/elearning",
    component: _3ef95ccd,
    name: "about-setsource-insights-elearning___th"
  }, {
    path: "/th/about/setsource/insights/infographic",
    component: _1cd214c4,
    name: "about-setsource-insights-infographic___th"
  }, {
    path: "/th/about/setsource/insights/main",
    component: _1b765142,
    name: "about-setsource-insights-main___th"
  }, {
    path: "/th/about/setsource/insights/video",
    component: _91404076,
    name: "about-setsource-insights-video___th"
  }, {
    path: "/th/about/setsource/news-release/article",
    component: _50d5491c,
    name: "about-setsource-news-release-article___th"
  }, {
    path: "/th/about/setsource/news-release/ebook",
    component: _49f8cc4a,
    name: "about-setsource-news-release-ebook___th"
  }, {
    path: "/th/about/setsource/news-release/elearning",
    component: _377d157f,
    name: "about-setsource-news-release-elearning___th"
  }, {
    path: "/th/about/setsource/news-release/infographic",
    component: _034a68f6,
    name: "about-setsource-news-release-infographic___th"
  }, {
    path: "/th/about/setsource/news-release/main",
    component: _6538f76d,
    name: "about-setsource-news-release-main___th"
  }, {
    path: "/th/about/setsource/news-release/video",
    component: _ba207d12,
    name: "about-setsource-news-release-video___th"
  }, {
    path: "/th/about/setsource/yaek-yae/article",
    component: _efa738ba,
    name: "about-setsource-yaek-yae-article___th"
  }, {
    path: "/th/about/setsource/yaek-yae/ebook",
    component: _3f33a68a,
    name: "about-setsource-yaek-yae-ebook___th"
  }, {
    path: "/th/about/setsource/yaek-yae/elearning",
    component: _1e72d570,
    name: "about-setsource-yaek-yae-elearning___th"
  }, {
    path: "/th/about/setsource/yaek-yae/infographic",
    component: _03cff0a7,
    name: "about-setsource-yaek-yae-infographic___th"
  }, {
    path: "/th/about/setsource/yaek-yae/main",
    component: _725544dc,
    name: "about-setsource-yaek-yae-main___th"
  }, {
    path: "/th/about/setsource/yaek-yae/podcast",
    component: _542162b1,
    name: "about-setsource-yaek-yae-podcast___th"
  }, {
    path: "/th/about/setsource/yaek-yae/video",
    component: _395d21e8,
    name: "about-setsource-yaek-yae-video___th"
  }, {
    path: "/th/education-research/education/happymoney/glossary",
    component: _57127904,
    name: "education-research-education-happymoney-glossary___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge",
    component: _35d01e2e,
    name: "education-research-education-happymoney-knowledge___th"
  }, {
    path: "/th/education-research/education/happymoney/search-result",
    component: _45053262,
    name: "education-research-education-happymoney-search-result___th"
  }, {
    path: "/th/education-research/research/article-paper/main",
    component: _477ddd82,
    name: "education-research-research-article-paper-main___th"
  }, {
    path: "/th/education-research/research/article-paper/Oldslug",
    component: _e8a0890e,
    name: "education-research-research-article-paper-Oldslug___th"
  }, {
    path: "/th/education-research/research/article-paper/set-cmri-research-paper",
    component: _168927e2,
    name: "education-research-research-article-paper-set-cmri-research-paper___th"
  }, {
    path: "/th/education-research/research/article-paper/set-cmri-working-paper",
    component: _1d810918,
    name: "education-research-research-article-paper-set-cmri-working-paper___th"
  }, {
    path: "/th/education-research/research/article-paper/set-note",
    component: _525517c6,
    name: "education-research-research-article-paper-set-note___th"
  }, {
    path: "/th/education-research/research/database/main",
    component: _26810cae,
    name: "education-research-research-database-main___th"
  }, {
    path: "/th/education-research/research/market-report/ceo-survey",
    component: _0f6abb73,
    name: "education-research-research-market-report-ceo-survey___th"
  }, {
    path: "/th/education-research/research/market-report/international-roundups",
    component: _6ef2123c,
    name: "education-research-research-market-report-international-roundups___th"
  }, {
    path: "/th/education-research/research/market-report/investor-sentiment-index-cmri",
    component: _c675cab4,
    name: "education-research-research-market-report-investor-sentiment-index-cmri___th"
  }, {
    path: "/th/education-research/research/market-report/main",
    component: _2ffd3d92,
    name: "education-research-research-market-report-main___th"
  }, {
    path: "/th/education-research/research/market-report/market-highlights",
    component: _58dcb6e7,
    name: "education-research-research-market-report-market-highlights___th"
  }, {
    path: "/th/education-research/research/market-report/Oldslug",
    component: _eb08bd2e,
    name: "education-research-research-market-report-Oldslug___th"
  }, {
    path: "/th/education-research/research/market-report/set-monthly-market-report",
    component: _78acb971,
    name: "education-research-research-market-report-set-monthly-market-report___th"
  }, {
    path: "/th/education-research/research/research-grant/all-research-grant",
    component: _2a06b13c,
    name: "education-research-research-research-grant-all-research-grant___th"
  }, {
    path: "/th/listing/ipo/upcoming-ipo/foreign-listing",
    component: _4961248f,
    name: "listing-ipo-upcoming-ipo-foreign-listing___th"
  }, {
    path: "/th/listing/ipo/upcoming-ipo/infrastructure-fund",
    component: _0c185eb3,
    name: "listing-ipo-upcoming-ipo-infrastructure-fund___th"
  }, {
    path: "/th/listing/ipo/upcoming-ipo/mai",
    component: _4a6e4c59,
    name: "listing-ipo-upcoming-ipo-mai___th"
  }, {
    path: "/th/listing/ipo/upcoming-ipo/reit",
    component: _3bd8766c,
    name: "listing-ipo-upcoming-ipo-reit___th"
  }, {
    path: "/th/listing/ipo/upcoming-ipo/set",
    component: _e17bd634,
    name: "listing-ipo-upcoming-ipo-set___th"
  }, {
    path: "/th/market/index/gms-exchanges/trading",
    component: _310ef50d,
    name: "market-index-gms-exchanges-trading___th"
  }, {
    path: "/th/market/information/member-list/main",
    component: _2d29bf54,
    name: "market-information-member-list-main___th"
  }, {
    path: "/th/market/information/securities-list/concessions-list",
    component: _74edc59a,
    name: "market-information-securities-list-concessions-list___th"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/article",
    component: _21e72f52,
    name: "education-research-education-happymoney-knowledge-article___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/ebook",
    component: _5c6e182a,
    name: "education-research-education-happymoney-knowledge-ebook___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/elearning",
    component: _34a9715f,
    name: "education-research-education-happymoney-knowledge-elearning___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/infographic",
    component: _66cf4cd6,
    name: "education-research-education-happymoney-knowledge-infographic___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/main",
    component: _0afaaf8d,
    name: "education-research-education-happymoney-knowledge-main___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/podcast",
    component: _2447a340,
    name: "education-research-education-happymoney-knowledge-podcast___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/podcastplaylist",
    component: _3bfa0835,
    name: "education-research-education-happymoney-knowledge-podcastplaylist___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/video",
    component: _9535e552,
    name: "education-research-education-happymoney-knowledge-video___en"
  }, {
    path: "/en/education-research/education/happymoney/knowledge/videoplaylist",
    component: _2e59eaec,
    name: "education-research-education-happymoney-knowledge-videoplaylist___en"
  }, {
    path: "/en/tsd/services/investors/calculators/right-offering",
    component: _bd649b94,
    name: "tsd-services-investors-calculators-right-offering___en"
  }, {
    path: "/en/tsd/services/investors/calculators/stock-dividend",
    component: _9571f236,
    name: "tsd-services-investors-calculators-stock-dividend___en"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/article",
    component: _21e72f52,
    name: "education-research-education-happymoney-knowledge-article___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/ebook",
    component: _5c6e182a,
    name: "education-research-education-happymoney-knowledge-ebook___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/elearning",
    component: _34a9715f,
    name: "education-research-education-happymoney-knowledge-elearning___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/infographic",
    component: _66cf4cd6,
    name: "education-research-education-happymoney-knowledge-infographic___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/main",
    component: _0afaaf8d,
    name: "education-research-education-happymoney-knowledge-main___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/podcast",
    component: _2447a340,
    name: "education-research-education-happymoney-knowledge-podcast___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/podcastplaylist",
    component: _3bfa0835,
    name: "education-research-education-happymoney-knowledge-podcastplaylist___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/video",
    component: _9535e552,
    name: "education-research-education-happymoney-knowledge-video___th"
  }, {
    path: "/th/education-research/education/happymoney/knowledge/videoplaylist",
    component: _2e59eaec,
    name: "education-research-education-happymoney-knowledge-videoplaylist___th"
  }, {
    path: "/th/tsd/services/investors/calculators/right-offering",
    component: _bd649b94,
    name: "tsd-services-investors-calculators-right-offering___th"
  }, {
    path: "/th/tsd/services/investors/calculators/stock-dividend",
    component: _9571f236,
    name: "tsd-services-investors-calculators-stock-dividend___th"
  }, {
    path: "/en/tsd/services/investors/transaction/certificate/certificate-loss-list",
    component: _c199550a,
    name: "tsd-services-investors-transaction-certificate-certificate-loss-list___en"
  }, {
    path: "/en/tsd/services/investors/transaction/certificate/name-changes-record",
    component: _a211ddf4,
    name: "tsd-services-investors-transaction-certificate-name-changes-record___en"
  }, {
    path: "/th/tsd/services/investors/transaction/certificate/certificate-loss-list",
    component: _c199550a,
    name: "tsd-services-investors-transaction-certificate-certificate-loss-list___th"
  }, {
    path: "/th/tsd/services/investors/transaction/certificate/name-changes-record",
    component: _a211ddf4,
    name: "tsd-services-investors-transaction-certificate-name-changes-record___th"
  }, {
    path: "/en/education-research/education/happymoney/glossary/:slug?",
    component: _555bd7bc,
    name: "education-research-education-happymoney-glossary-slug___en"
  }, {
    path: "/en/market/product/dr/quote/:slug",
    component: _32929c5c,
    name: "market-product-dr-quote-slug___en"
  }, {
    path: "/en/market/product/drx/quote/:slug",
    component: _2065be0c,
    name: "market-product-drx-quote-slug___en"
  }, {
    path: "/en/market/product/dw/quote/:slug",
    component: _59aeb017,
    name: "market-product-dw-quote-slug___en"
  }, {
    path: "/en/market/product/etf/quote/:slug",
    component: _651c6239,
    name: "market-product-etf-quote-slug___en"
  }, {
    path: "/en/market/product/stock/quote/:slug",
    component: _2060ea90,
    name: "market-product-stock-quote-slug___en"
  }, {
    path: "/th/education-research/education/happymoney/glossary/:slug?",
    component: _555bd7bc,
    name: "education-research-education-happymoney-glossary-slug___th"
  }, {
    path: "/th/market/product/dr/quote/:slug",
    component: _32929c5c,
    name: "market-product-dr-quote-slug___th"
  }, {
    path: "/th/market/product/drx/quote/:slug",
    component: _2065be0c,
    name: "market-product-drx-quote-slug___th"
  }, {
    path: "/th/market/product/dw/quote/:slug",
    component: _59aeb017,
    name: "market-product-dw-quote-slug___th"
  }, {
    path: "/th/market/product/etf/quote/:slug",
    component: _651c6239,
    name: "market-product-etf-quote-slug___th"
  }, {
    path: "/th/market/product/stock/quote/:slug",
    component: _2060ea90,
    name: "market-product-stock-quote-slug___th"
  }, {
    path: "/en/market/product/dr/quote/:slug?/factsheet",
    component: _113ef21a,
    name: "market-product-dr-quote-slug-factsheet___en"
  }, {
    path: "/en/market/product/dw/quote/:slug?/factsheet",
    component: _15f5dab8,
    name: "market-product-dw-quote-slug-factsheet___en"
  }, {
    path: "/en/market/product/etf/quote/:slug?/factsheet",
    component: _7119abda,
    name: "market-product-etf-quote-slug-factsheet___en"
  }, {
    path: "/en/market/product/stock/quote/:slug?/factsheet",
    component: _b613064e,
    name: "market-product-stock-quote-slug-factsheet___en"
  }, {
    path: "/th/market/product/dr/quote/:slug?/factsheet",
    component: _113ef21a,
    name: "market-product-dr-quote-slug-factsheet___th"
  }, {
    path: "/th/market/product/dw/quote/:slug?/factsheet",
    component: _15f5dab8,
    name: "market-product-dw-quote-slug-factsheet___th"
  }, {
    path: "/th/market/product/etf/quote/:slug?/factsheet",
    component: _7119abda,
    name: "market-product-etf-quote-slug-factsheet___th"
  }, {
    path: "/th/market/product/stock/quote/:slug?/factsheet",
    component: _b613064e,
    name: "market-product-stock-quote-slug-factsheet___th"
  }, {
    path: "/en/market/product/dr/quote/:slug?/*",
    component: _460a2242,
    name: "market-product-dr-quote-slug-all___en"
  }, {
    path: "/th/market/product/dr/quote/:slug?/*",
    component: _460a2242,
    name: "market-product-dr-quote-slug-all___th"
  }, {
    path: "/en/market/product/drx/quote/:slug?/*",
    component: _3d85afce,
    name: "market-product-drx-quote-slug-all___en"
  }, {
    path: "/th/market/product/drx/quote/:slug?/*",
    component: _3d85afce,
    name: "market-product-drx-quote-slug-all___th"
  }, {
    path: "/en/market/product/dw/quote/:slug?/*",
    component: _3d6250b8,
    name: "market-product-dw-quote-slug-all___en"
  }, {
    path: "/th/market/product/dw/quote/:slug?/*",
    component: _3d6250b8,
    name: "market-product-dw-quote-slug-all___th"
  }, {
    path: "/en/market/product/etf/quote/:slug?/*",
    component: _471eeac6,
    name: "market-product-etf-quote-slug-all___en"
  }, {
    path: "/th/market/product/etf/quote/:slug?/*",
    component: _471eeac6,
    name: "market-product-etf-quote-slug-all___th"
  }, {
    path: "/en/market/product/stock/quote/:slug?/*",
    component: _36ffaa76,
    name: "market-product-stock-quote-slug-all___en"
  }, {
    path: "/th/market/product/stock/quote/:slug?/*",
    component: _36ffaa76,
    name: "market-product-stock-quote-slug-all___th"
  }, {
    path: "/en/market/information/member-list/:slug",
    component: _4e6eb7a3,
    name: "market-information-member-list-slug___en"
  }, {
    path: "/th/market/information/member-list/:slug",
    component: _4e6eb7a3,
    name: "market-information-member-list-slug___th"
  }, {
    path: "/en/education-research/research/database/:slug/examples",
    component: _efea6d3a,
    name: "education-research-research-database-slug-examples___en"
  }, {
    path: "/th/education-research/research/database/:slug/examples",
    component: _efea6d3a,
    name: "education-research-research-database-slug-examples___th"
  }, {
    path: "/en/market/index/:slug",
    component: _0a3e2e18,
    name: "market-index-slug___en"
  }, {
    path: "/th/market/index/:slug",
    component: _0a3e2e18,
    name: "market-index-slug___th"
  }, {
    path: "/en/market/index/:slug/overview",
    component: _e14f2dba,
    name: "market-index-slug-overview___en"
  }, {
    path: "/th/market/index/:slug/overview",
    component: _e14f2dba,
    name: "market-index-slug-overview___th"
  }, {
    path: "/en/market/index/:slug/:industry",
    component: _721dfc46,
    name: "market-index-slug-industry___en"
  }, {
    path: "/th/market/index/:slug/:industry",
    component: _721dfc46,
    name: "market-index-slug-industry___th"
  }, {
    path: "/en/market/index/:slug/:industry/:sector",
    component: _2fa3573c,
    name: "market-index-slug-industry-sector___en"
  }, {
    path: "/th/market/index/:slug/:industry/:sector",
    component: _2fa3573c,
    name: "market-index-slug-industry-sector___th"
  }, {
    path: "/en/preview-form/:slug?",
    component: _100365e4,
    name: "preview-form-slug___en"
  }, {
    path: "/th/preview-form/:slug?",
    component: _100365e4,
    name: "preview-form-slug___th"
  }, {
    path: "/en/preview/*",
    component: _77e72944,
    name: "preview-all___en"
  }, {
    path: "/th/preview/*",
    component: _77e72944,
    name: "preview-all___th"
  }, {
    path: "/en/*",
    component: _2efaf336,
    name: "all___en"
  }, {
    path: "/th/*",
    component: _2efaf336,
    name: "all___th"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
